const events = [
    {
        title: "Society for Neuroscience 2024",
        venue: "October 2024. Chicago IL",
        date: new Date(2024, 10, 4),
        contents: [`Come find us at booth #1732!`]
    },
    {
        title: "BRAIN Initiative CONNECTS Meeting 2024",
        venue: "October 4, 2024. Chicago IL",
        date: new Date(2024, 10, 4),
        contents: [``]
    },
    {
        title: "BRAIN Initiative Cell Atlas Network (BICAN) Meeting 2023",
        venue: "November 10, 2023. Johns Hopkins University Applied Physics Laboratory, Laurel MD",
        date: new Date(2023, 10, 10),
        contents: [`This year's BICAN event was held at JHU/APL [Building 201](https://www.jhuapl.edu/about/directions).`]
    },
    {
        title: "BRAIN Initiative CONNECTS Meeting 2023",
        venue: "November 10, 2023. Johns Hopkins University Applied Physics Laboratory, Laurel MD",
        date: new Date(2023, 10, 10),
        contents: [`This year's BICAN event was held at JHU/APL [Building 200](https://www.jhuapl.edu/about/directions).`]
    },
    {
        title: "BRAIN Initiative SfN Toolmakers Event",
        venue: "November 13, 2022",
        contents: [
            `[BRAIN Initiative toolmaker social at the 2022 Society for Neuroscience Meeting](https://brainblog.nih.gov/brain-blog/call-participation-brain-initiative-toolmaker-social-2022-society-neuroscience-meeting)`,
        ],
        date: new Date(2022, 11, 13)
    }, {
        title: "AWS Public Sector Summit",
        venue: "May 23, 2022",
        contents: [
            `[Innovate with petabyte-scale open data in the life sciences](https://youtu.be/g3hNwmdmGcM?t=1515)`,
        ],
        date: new Date(2022, 5, 23)
    }, {
        title: "BRAIN Initiative Investigators Meeting 2020",
        venue: "June 2, 2020 (Virtual)",
        contents: [
            `[2020 6th annual BRAIN Initiative Investigators Virtual Meeting](https://www.labroots.com/ms/virtual-event/2020-6th-annual-brain-initiative-investigators-virtual-meeting)`,
            `11:30 AM – 1:00 PM: [Symposium 5: Toward Petascale and Exascale Connectomics](https://www.labroots.com/ms/webinar/symposium-5-petascale-exascale-connectomics)`,
        ],
        date: new Date(2020, 6, 2)
    }, {
        title: "Society for Neuroscience 2019",
        venue: "October 19 2019, Chicago",
        contents: [
            `<a onclick="gol('https://www.abstractsonline.com/pp8/#!/7883/presentation/64265')" href="https://www.abstractsonline.com/pp8/#!/7883/presentation/64265">SFN Connectomics Analytics Dynamic Poster Session</a>`,
            `Come learn about BossDB!`
        ],
        date: new Date(2019, 10, 19)
    }, {
        title: "AWS Public Sector Summit",
        venue: "June 2019, Washington D.C",
        contents: [
            `<a onclick="gol('https://awsdc19.smarteventscloud.com/connect/sessionDetail.ww?SESSION_ID=295421')" href="https://awsdc19.smarteventscloud.com/connect/sessionDetail.ww?SESSION_ID=295421">Getting Started with Serverless Architectures</a>`,
            `Ray Chang — Solution Architect, AWS <br />`,
            `Sandy Hider — BOSS Technical Task Lead, APL<br />`
        ],
        date: new Date(2019, 6, 11)
    }, {
        title: "AWS re:invent",
        venue: "November 2017, Las Vegas",
        contents: [
            `Mike Colson — Solutions Architect, AWS<br />`,
            `Dean Kleissas — VP Engineering, Gigantum`
        ],
        date: new Date(2017, 11, 27)
    }, {
        title: "Society for Neuroscience 2017",
        venue: "October 2017, Washington DC",
        contents: [],
        date: new Date(2017, 10, 11)
    }
];

export default events;
