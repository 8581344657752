<template>
    <div class="box">
        <h1 class="title">Events</h1>
        <div v-for="event in events" v-bind:key="event.title">
            <h2 class="title is-4">
                <span v-if="event.date < today" class="tag is-danger is-light is-normal">Past</span>
                {{ event.title }}
            </h2>
            <h3 class="subtitle is-7">{{ event.venue }}</h3>
            <div v-html="event.contents" />
            <br />
        </div>
    </div>
</template>


<script>
import showdown from "showdown";

import events from "@/events.js";

let loadedEvents = events.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
})
const today = new Date();
let selectedEvents = loadedEvents.filter((event) => {
    // Featured, or in the last 60 days:
    return event.featured || event.date > (today - 1000 * 60 * 60 * 24 * 60);
});

// If the list is empty, just show the most recent event:
if (selectedEvents.length === 0) {
    selectedEvents = loadedEvents.slice(0, 1);
}

export default {
    name: "EventsCard",

    data() {
        let converter = new showdown.Converter({
            simplifiedAutoLink: true,
        });
        return {
            events: selectedEvents.map((event) => {
                return {
                    ...event,
                    contents: converter.makeHtml(event.contents.join("\n\n")),
                };
            }),
            today,
        };
    },
};
</script>
