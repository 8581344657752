<template>
    <span style="font-size: 1em">
        <img :src="ASSETS_BASE_URL + 'logo.png'" :style="{ width }" />
    </span>
</template>

<script>
import { ASSETS_BASE_URL } from '@/assets.js';

export default {
    props: {
        width: { type: Number, default: 52 },
    },
    data() {
        return {
            ASSETS_BASE_URL,
        };
    },
};
</script>
