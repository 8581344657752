<template>
    <div class="home">
        <Home />
    </div>
</template>

<script>
import Home from '@/components/Home.vue'

export default {
    name: 'home',
    components: {
        Home
    }
}
</script>
